/* eslint-disable react/no-array-index-key */
import React, { ComponentType } from 'react';
import { Store } from 'redux';
import { Provider } from 'react-redux';
import { createRoot } from 'react-dom/client';
import { StoreManager } from '@piwikpro/store';
import { Container } from 'inversify';
import { DIProvider } from './DIProvider';
import { ConfigService } from './ConfigService';
import { Logger } from './Logger';
import { DependencyLoader, DependencyBucket, LazyCratesMap } from './DependencyLoader';
import {
  ICrateComponentProps,
  ICrateSettings,
  ICrateConstructor,
  IMainCrateSettings,
} from './Crate';
import { containerStore } from './useService';
import appPermissionLevels from '../config/appPermissionLevels';
import metaSitePermissionLevels from '../config/metaSitePermissionLevels';
import userPreferences from '../config/userPreferences';
import accessFactory from '../config/access';
import { env } from '../container';

export class Platform {
  private dl: DependencyLoader;

  private panels: React.ComponentClass[] = [];

  private providers: React.ComponentClass[] = [];

  private lazyCrates: LazyCratesMap = {};

  constructor(
    private container: Container = new Container(),
    config: ConfigService = new ConfigService(env),
    logger: Logger = new Logger(console),
    private storeManager: StoreManager = new StoreManager(),
    initialStore: { [key: string]: () => {} } = {},
  ) {
    this.container.bind('LOG_TO_CONSOLE').toConstantValue(process.env.NODE_ENV !== 'production' && process.env.NODE_ENV !== 'test');
    this.container.bind('IS_LOCIZE_ENABLED').toConstantValue(process.env.NODE_ENV !== 'production' && process.env.NODE_ENV !== 'test');
    this.container.bind('initialState').toConstantValue((window as any).initialState);
    this.container.bind('accessManager').toDynamicValue(accessFactory);
    this.container.bind('permissionLevels').toDynamicValue(appPermissionLevels).inSingletonScope();
    this.container.bind('metaSitePermissionLevels').toDynamicValue(metaSitePermissionLevels).inSingletonScope();
    this.container.bind('userPreferences').toDynamicValue(userPreferences).inSingletonScope();
    this.container.bind('document').toConstantValue(document);
    this.container.bind('logger').toConstantValue(logger);
    this.container.bind('config').toConstantValue(config);
    this.container.bind('localStorage').toConstantValue(window.localStorage);
    this.container.bind('location').toConstantValue(window.location);
    this.container.bind('store').toConstantValue(this.storeManager.createStore(initialStore));

    this.dl = new DependencyLoader(
      this.storeManager,
      container,
      logger,
      config,
      this.panels,
      this.providers,
      this.lazyCrates,
    );

    containerStore.container = this.container;
  }

  mount(
    Component: ComponentType<ICrateComponentProps>,
    additionalProps: any = {},
  ): React.ReactElement {
    const content = this.providers
      .slice()
      .reverse()
      .reduce<any>((Children, NextProvider) => (
      /* eslint-disable */
        <NextProvider>
          {Children}
        </NextProvider>
      ),
    (
      <>
        {this.panels.map((Panel, index) => <Panel key={index} />)}
        <Component
          {...additionalProps}
          t={(key: any) => key}
        />
      </>
    ));
    /* eslint-enable */

    return (
      <DIProvider container={this.container} lazyCrates={this.lazyCrates}>
        <Provider store={this.container.get<Store>('store')}>
          {content}
        </Provider>
      </DIProvider>
    );
  }

  /**
   * Loading crate with all of it dependencies
   */
  async load(Crate: ICrateConstructor<ICrateSettings>): Promise<Array<DependencyBucket>> {
    return this.dl.load(Crate);
  }

  async configure(buckets: Array<DependencyBucket>): Promise<void> {
    return this.dl.configure(buckets);
  }

  async init(buckets: Array<DependencyBucket>): Promise<void> {
    return this.dl.init(buckets);
  }

  async run(Crate: ICrateConstructor<IMainCrateSettings>): Promise<void> {
    const buckets = await this.load(Crate);

    await this.configure(buckets);
    await this.init(buckets);

    const root = createRoot(document.getElementById('root')!);
    root.render(await this.mount(Crate.settings.bootstrap));
  }
}
