import React from 'react';
import classNameBind from 'classnames/bind';
import { WithTranslation } from 'react-i18next';
import { History } from 'history';
import { ConnectedRouter } from 'connected-react-router';
import { Switch, Route } from 'react-router-dom';
import { withInject } from '@piwikpro/platform';
import {
  Root,
  SplashScreen,
  withTranslation,
} from '@piwikpro/ui-components';
import FirstUserSetupPassword from './views/AccountSetup/FirstUserSetupPassword';
import InvitedUserSetupPassword from './views/AccountSetup/InvitedUserSetupPassword';
import RequestResetForm from './views/ResetPassword/RequestResetForm';
import ResetForm from './views/ResetPassword/ResetForm';
import Login from './views/Login';
import styles from './AstronAuth.module.css';
import { LOGIN_URL, RESET_PASSWORD_URL } from './constants';
import Errors from './views/Errors';
import Marketing from './views/Marketing';
import NoMarketingViewContainer from './components/NoMarketingViewContainer/NoMarketingViewContainer';

const bindClasses = classNameBind.bind(styles);

interface InjectedProps {
  history: History
}

interface Props extends InjectedProps, WithTranslation {}

export const AstronAuth = ({
  history,
  tReady,
}: Props) => {
  if (!tReady) return null;

  return (
    <ConnectedRouter history={history}>
      <Root title="Piwik PRO">
        <div className={bindClasses('main-wrapper')}>
          <Root.Content>
            <Switch>
              <Route path={[`${LOGIN_URL}/maintenance-mode`]}>
                <NoMarketingViewContainer>
                  <Errors
                    viewType="login"
                    reason="maintenance-mode"
                    history={history}
                  />
                </NoMarketingViewContainer>
              </Route>
              <Route path={[`${LOGIN_URL}/error/sso`]}>
                <NoMarketingViewContainer>
                  <Errors
                    viewType="login"
                    reason="sso-failure"
                    history={history}
                  />
                </NoMarketingViewContainer>
              </Route>
              <Route path={[LOGIN_URL]}>
                <Marketing>
                  <Login />
                </Marketing>
              </Route>
              <Route path={[`${RESET_PASSWORD_URL}/:setPasswordCode`]}>
                <ResetForm />
              </Route>
              <Route path={[RESET_PASSWORD_URL]}>
                <Marketing>
                  <RequestResetForm />
                </Marketing>
              </Route>
              <Route path={['/account/info/account-deleted']}>
                <NoMarketingViewContainer>
                  <Errors
                    history={history}
                    viewType="general-info"
                    reason="account-deleted"
                  />
                </NoMarketingViewContainer>
              </Route>
              <Route
                path={[
                  '/account/setup/password/:setPasswordCode',
                  '/account/setup/:reservationCode?',
                ]}
              >
                <FirstUserSetupPassword />
              </Route>
              <Route path={['/account/user/setup/password/:setPasswordCode']}>
                <NoMarketingViewContainer>
                  <InvitedUserSetupPassword />
                </NoMarketingViewContainer>
              </Route>
              <Route>
                <SplashScreen type="notFound" />
              </Route>
            </Switch>
          </Root.Content>
        </div>
      </Root>
    </ConnectedRouter>
  );
};

export default withInject<InjectedProps>({
  history: 'RouterCrate.history',
})(withTranslation(['astronauth'])(AstronAuth));
